@import "nprogress/nprogress.css"; //styles of nprogress
@import "./_variables.scss";

* {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

body {
  background-color: $off-white !important;
}
@media print {
  body {
    background-color: transparent !important;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  img[loading="lazy"] {
    clip-path: inset(0.5px);
  }
}

a {
  text-decoration: none !important;
  color: black !important;
}

a:hover {
  color: black !important;
  text-decoration: underline !important;
}

html {
  min-width: 360px;
  scroll-behavior: auto !important;
}

.linkNoDecoration:hover{
  text-decoration: none !important;
}

// LOGO
.clickable {
  object-fit: cover;
  cursor: pointer;
  padding: 1rem;
}

.unclickable {
  object-fit: cover;
  cursor: default;
  padding: 1rem;
}

.react-photo-gallery--gallery > div {
  justify-content: center !important;
}

// PAGE MAX-WIDTH
.contentMaxWidth720 {
  max-width: 720px !important;
}

.contentMaxWidth960 {
  max-width: 960px !important;
}

.contentMaxWidth1140 {
  max-width: 1140px !important;
}

// Set red color for error messages and invalid input
.invalid {
  color: $red-links;
}

// USER EXPIRE LOGIN DATEPICKER
.expire-datePicker {
  width: 100%;
}

//Remove bullets from list
.nobulletsul {
  list-style-type: none !important;
}

// Disable arrows in form type="number"
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// Scrollbar
::-webkit-scrollbar-track {
  @media (min-width: 576px) {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
}

::-webkit-scrollbar {
  @media (min-width: 576px) {
    width: 12px;
    background-color: #f5f5f5;
  }
}

::-webkit-scrollbar-thumb {
  @media (min-width: 576px) {
    width: 50px;
    background-color: $scrollbar;
    border: 1px solid #b4b4b4;
  }
}

::-webkit-scrollbar-thumb:hover {
  @media (min-width: 576px) {
    width: 50px !important;
    background-color: $scrollbar-hover;
    border: 1px solid #b4b4b4;
  }
}

::-webkit-scrollbar-thumb:active {
  @media (min-width: 576px) {
    background-color: $scrollbar-active;
    border: 1px solid #b4b4b4;
  }
}

// Centered carousel dots / react-slick
.slick-dots {
  justify-content: center;
}

// Carousel buttons
.slick-prev:before {
  font-size: 25px;
  color: black !important;
  left: -30px !important;
  left: 3% !important;
  z-index: 1 !important;
}
.slick-next:before {
  font-size: 25px;
  color: black !important;
  right: 30px !important;
  right: 3% !important;
  z-index: 1 !important;
}

// Custom class add a red asterisk to required form or label.
.required-label::after {
  content: "*";
  color: red;
  margin-left: 2px;
}

// Custom scrollbar dropdown shoppingbag
#dropdown-shoppingBag::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
}

#dropdown-shoppingBag::-webkit-scrollbar {
  width: 8px;
  background-color: #ffffff;
}

#dropdown-shoppingBag::-webkit-scrollbar-thumb {
  background-color: $scrollbar-dropdown-shoppingbag;
  border: 1px solid #d2d2d2;
}

#dropdown-shoppingBag::-webkit-scrollbar-thumb:hover {
  background-color: $scrollbar-dropdown-shoppingbag-hover;
  border: 1px solid #d2d2d2;
}

#dropdown-shoppingBag::-webkit-scrollbar-thumb:active {
  background-color: $scrollbar-dropdown-shoppingbag-active;
  border: 1px solid #d2d2d2;
}

// Datepicker
.react-datepicker__month-container {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif !important;
}

// Breadcrumb
.breadcrumb {
  background-color: $off-white !important;
}

.nav-link:focus {
  text-decoration: underline !important;
}

.a:focus {
  text-decoration: underline !important;
}

//Select number of elements on each page (products page)
.custom-select {
  border-radius: 0 !important;
}

// REACT SLICK PRODUCT PAGE CUSTOM PAGING
.thumb-product {
  bottom: -25px;
  display: contents !important;
  position: relative !important;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.thumb-product li {
  width: 100px !important;
  height: 150px !important;
  margin-left: 0 !important;
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

// FORM
//Change color of outline in input, textarea, select-form
textarea:focus,
textarea.form-control:focus,
select.form-control[size]:focus,
select.form-control[multiple]:focus,
select.form-control:focus,
select.form-control- textarea.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
input[type="plaintext"]:focus,
.form-control-plaintext,
.uneditable-input:focus {
  border-color: rgba(lightgray, 0.8);
  // box-shadow: 0 1px 1px rgba($form-focus-color, 0.075);
  box-shadow: 0 0 0 1px #a0a2a4;
  outline: 2px rgba($form-focus-color, 0.8);
}

.form-control:focus{
  border-color: $form-focus-color !important;
}

select.form-control-option:hover {
  background-color: black !important;
}

// Change the color of selected autofill input to white.
input:-webkit-autofill {
  box-shadow: 0 0 0 30px white inset !important;
}

//Form Control Disabled
.form-control:disabled,
.form-control[readonly] {
  border-color: $gray-border !important;
}

// Navbar toggle, remove border and outline when focus
.navbar-light .navbar-toggler {
  border: none;
}
// .navbar-toggler:focus {
//   outline: none;
// }

// TOAST position offset
// .Toastify__toast-container--top-right {
//   top: 4.5rem !important;
// }

// Toast progress bar Colors
.Toastify__toast {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif !important;
}

.Toastify__progress-bar--default {
  background: $off-black !important;
}

.Toastify__progress-bar--dark {
  background: $gray-footer !important;
}

.Toastify__toast--dark {
  background: $dark-gray !important;
}

// DROPDOWNS
//Change the dropdown menu container
.dropdown-menu {
  border-radius: 0 !important;
}

.dropdown-item:hover {
  background-color: $off-white !important;
}

.dropdown-item.active,
.dropdown-item:active {
  text-decoration: none;
  background-color: lightgray !important;
}

.dropdown-item:focus {
  background-color: $off-white !important;
}

.dropdown-link-active-bg {
  background-color: $off-white !important;
}

// Shopping bad dropdown
#shopping-bag-menu-item:hover {
  background-color: white !important;
  text-decoration: none !important;
}

#shopping-bag-menu-item {
  cursor: auto;
}

// ACCORDION
// .accordion-button:focus {
//   box-shadow: none !important;
// }
.accordion-button:not(.collapsed) {
  background-color: transparent;
  color: black !important;
}
.accordion-flush .accordion-item .accordion-button {
  background-color: transparent;
}

//Change color of select form.
select option:hover,
select option:focus,
select option:active {
  background: linear-gradient($gray-border, $gray-border);
  background-color: $gray-border !important; /* for IE */
  color: $form-focus-color !important;
}

select option:checked {
  background: linear-gradient($gray-border, $gray-border);
  background-color: $gray-border !important; /* for IE */
  color: $form-focus-color !important;
}

// //Custom Pill border
// .borderCustomPill {
//   box-shadow: black;
//   border-width: 1px;
// }

.modal-content {
  background-color: $off-white !important;
}

// X Button used in modals
.close {
  color: $off-black !important;
  opacity: 1 !important;
  text-shadow: none !important;
  font-size: 2rem !important;
}
// .close:focus {
//   outline: 0 !important;
// }

// CUSTOMIZE BUTTONS

// Bottone variant="link"
.btn-link {
  color: black !important;
}

//Light button
.btn-light {
  background-color: $off-white !important;
  border-color: $gray-border !important;
}

// Standard button
.btn {
  border-radius: 0 !important;
}

//Black button
.btn-dark {
  background-color: $off-black !important;
}

//REMOVE OUTLINE ON FOCUS / ACTIVE
// .btn:focus {
//   outline: none !important;
//   box-shadow: none !important;
// }
// .btn-dark:focus {
//   outline: $off-black !important;
//   box-shadow: $off-black !important;
// }
.btn-link:active {
  outline: none !important;
  box-shadow: none !important;
}
.btn-link:focus {
  outline: none !important;
  box-shadow: none !important;
}
// .btn:active {
//   outline: none !important;
// }

// Hover
.btn-dark:hover {
  background-color: $dark-btn-hover !important;
  border-color: $dark-gray !important;
}
.btn-light:hover {
  background-color: $light-btn-hover !important;
  opacity: 0.9;
}

.btn-danger:hover {
  background-color: #bb2d3b !important;
}

// Active
// .btn-light:active {
//   background-color: $light-btn-hover !important;
//   opacity: 0.9;
//   border-color: $off-black !important;
// }

// Focus
// .btn-light:focus {
//   border-color: $off-black !important;
// }

// Breadcrumb "/"
.breadcrumb-item::before {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

//Black pagination active
.page-item.active .page-link {
  color: $off-white !important;
  background-color: $off-black !important;
  border-color: $form-focus-color !important;
}

//Remove all rounded corner from components
.form-control {
  border-radius: 0 !important;
}

.card-img-top {
  border-radius: 0 !important;
}

.card-img-overlay {
  border-radius: 0 !important;
}

.card {
  border-radius: 0 !important;
}

// Add a pointer when hovering checkboxes
input[type="radio"],
input[type="checkbox"] {
  cursor: pointer;
}

// BADGES
// Remove gray outline from badges
.badge {
  border-color: $off-black;
}

// Horizontal line style
hr {
  background-color: $gray-border !important;
}

// Progress bar customization
#nprogress .bar {
  background: $off-black !important;
}

/* Fancy blur effect */
#nprogress .peg {
  box-shadow: 0 0 10px $off-black, 0 0 5px $off-black !important;
}

#nprogress .spinner-icon {
  border-top-color: $off-black !important;
  border-left-color: $off-black !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

// Hide pagination when pages count is 0 (only one page)
.page-0 {
  display: none;
}

.spinner-border {
  width: 1.5rem !important;
  height: 1.5rem !important;
  border: 0.1em solid currentColor !important;
  display: inline-block;
  vertical-align: text-bottom;
  border-right-color: transparent !important;
  // border-right: .25em solid transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}
// CAROUSEL (slick-carousel)
// Carousel thumbnail ul
.slick-dots {
  display: flex !important;
}

// CAROUSEL THUMB STYLE
.slick-thumb {
  display: contents !important;
  position: relative !important;
}

.slick-thumb li {
  width: 100px !important;
  margin-left: 0 !important;
}

// LIGHTBOX
// Topbar
.ril__toolbar {
  height: 0px !important;
}
// Background
.ril__outer {
  background-color: rgba(0, 0, 0, 0.55) !important;
}

// Prev and next buttons
.ril__navButtons:focus {
  outline: none !important;
  box-shadow: none !important;
}

.ril__navButtons {
  opacity: 1 !important;
}

// CUSTOM HEADER / FOOTER LINK
.btn-menu {
  outline: none !important;
  box-shadow: none !important;
}
.btn-menu:hover {
  text-decoration: underline !important;
}
.btn-menu:focus {
  text-decoration: underline !important;
}

// MATERIAL STEPPER
.MuiStepButton-root:hover .MuiStepIcon-root.MuiStepIcon-active {
  fill: #3f51b5;
}

// Div like button with hover effect
.hover:hover {
  background-color: lightgray;
}

// Custom colored square, class by size
.square-xs {
  height: 1rem;
  width: 1rem;
  max-height: 1rem;
  max-width: 1rem;
  min-height: 1rem;
  min-width: 1rem;
}

.square-s {
  height: 1.5rem;
  width: 1.5rem;
  max-height: 1.5rem;
  max-width: 1.5rem;
  min-height: 1.5rem;
  min-width: 1.5rem;
}

.square-m {
  height: 2rem;
  width: 2rem;
  max-height: 2rem;
  max-width: 2rem;
  min-height: 2rem;
  min-width: 2rem;
}

.square-l {
  height: 2.5rem;
  width: 2.5rem;
  max-height: 2.5rem;
  max-width: 2.5rem;
  min-height: 2.5rem;
  min-width: 2.5rem;
}

// CUSTOM BACKGROUND COLORS CLASSES + TEXT COLOR //
.off-white {
  color: $black;
  background-color: $off-white;
}

.off-black {
  color: $off-white;
  background-color: $off-black;
}

.orange {
  color: $black;
  background-color: $orange;
}

.sky-blue {
  color: $black;
  background-color: $sky-blue;
}

.beige {
  color: $black;
  background-color: $beige;
}

.white {
  color: $black;
  background-color: $white;
}

.blue {
  color: $off-white;
  background-color: $blue;
}

.yellow {
  color: $off-black;
  background-color: $yellow;
}

.gray {
  color: $off-white;
  background-color: $gray;
}

.black {
  color: $off-white;
  background-color: $black;
}

.pink {
  color: $off-white;
  background-color: $pink;
}

.red {
  color: $off-white;
  background-color: $red;
}

.green {
  color: $off-white;
  background-color: $green;
}

.violet {
  color: $off-white;
  background-color: $violet;
}

.banner-green {
  color: $off-white;
  background-color: $banner-green;
  opacity: 0.9;
}
.banner-orange {
  color: $off-white;
  background-color: $banner-orange;
  opacity: 0.9;
}
.banner-blue {
  color: $off-white;
  background-color: $banner-blue;
  opacity: 0.9;
}
