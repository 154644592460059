.cursor_default {
  cursor: default;
  border-radius: 0;
  object-fit: cover;
}

.cursor_pointer {
  cursor: pointer;
  border-radius: 0;
  object-fit: cover;
}

.productName {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 8rem;
  white-space: nowrap;
}

.imageCol {
  max-width: 8rem;
}