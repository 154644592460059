@import "@/styles/_variables.scss";

.all_links {
  @media (min-width: 992px) {
    justify-content: center !important;
    flex-wrap: wrap !important;
    white-space: nowrap;
  }
}

.navdropdown {
  background-color: $off-white;
}
