.customDropdownToggle {
  font-size: 13px !important;
}

.mobile_links {
  display: flex;
  flex-direction: row !important;
}

.menu_item:hover {
  text-decoration: none !important;
  cursor: default;
  background-color: white !important;
}

.menu_item {
  text-decoration: none !important;
  cursor: default;
  background-color: white !important;
}

.button {
  position: relative;
  max-width: 3.3rem;
}

.badge {
  border-radius: 50% !important;
}

.sup {
  position: absolute;
  top: 0rem !important;
  right: 0rem !important;
}
