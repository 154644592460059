.breadcrumb {
  overflow: hidden;
  font: 14px Sans-Serif;

}

.breadcrumb li:last-child a {
  color: black;
  pointer-events: none;
  cursor: default;
  font-weight: bold !important;
}

.inactiveItemClassName:hover {
  text-decoration: none !important;
  cursor: default;
}