.dropdown_wrapper {
  width: 15rem;
  background-color: white !important;
}

.dropdown_wrapper:hover {
  background-color: white !important;
}

.product_wrapper {
  max-height: 21rem !important;
  overflow-y:auto;
  overflow-x: hidden;
}

