@import "@/styles/_variables.scss";

// NO TEXT DECORATION ON HOVER
.text_decoration_none_hover:hover {
  text-decoration: none !important;
}

.button_style {
  width: 100%;
  height: 100%;
}

.form_wrapper {
  padding: 0rem 1.5rem 0rem 1.5rem;
  @media (max-width: 576px) {
    padding: 0rem;
  }
}
