@import "@/styles/_variables.scss";

.searchnav {
  right: 0;
  position: absolute;
}

.searchbar {
  border-bottom: 1px solid $dark-gray;
  // width: 10rem;
}

.searchicon {
  border: none !important;
  background-color: transparent !important;
  padding: 0 !important;
}

.searchform {
  border: none !important;
  background-color: transparent !important;
}
