.text {
  white-space: nowrap;
  font-weight: 500 !important;
}

.spinner {
  position: absolute;
  left: calc(50% - 1rem);
  top: calc(50% - 0.75rem);
}
